$('#partner-slider-js').slick({
    infinite: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1024,
            settings: { 
                slidesToShow: 3
            }
        },

    ]

});

$('#medal_slider-js').slick({
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    vertical: true,
    responsive: [
        {
            breakpoint: 750,
            settings: {
                slidesToShow: 1,
                vertical: false
            }
        },
    ]

});

$(document).on('click', '.controls > .btn_prev', function(){
    $("#medal_slider-js").slick('slickPrev');
});

$(document).on('click', '.controls > .btn_next', function(){
    $("#medal_slider-js").slick('slickNext');
});

$('[data-fancybox="gallery"]').fancybox({});

$(document).on('click', '.medal-logo', function (e) {
    e.preventDefault();
    e.stopPropagation();
})


var count10 = $('.list-one .doc-list_item').length;
var count12 = $('.list-two .doc-list_item').length;

$(document).ready(function () {
    // $('.list-one .btn__open-list').html('Дивитися всі документи (<span>' + count10 + '</span>)');
    // $('.list-two .btn__open-list').html('Дивитися всі документи (<span>' + count12 + '</span>)');

    if (count10 < 3) {
        $('.list-one .btn__open-list').hide();
    }

    if (count12 < 3) {
        $('.list-two .btn__open-list').hide();
    }
});

$(document).on('click', '.list-one .btn__open-list', function () {

        if ($(this).hasClass('close')) {
            $(this).siblings('.doc-list_wrap').removeClass('close');
            $(this).removeClass('close');
            // $(this).html('Звернути список');
        } else {
            $(this).siblings('.doc-list_wrap').addClass('close');
            $(this).addClass('close');
            // $(this).html('Дивитися всі документи (<span>' + count10 + '</span>)');
        }


});

$(document).on('click', '.list-two .btn__open-list', function () {

        if ($(this).hasClass('close')) {
            $(this).siblings('.doc-list_wrap').removeClass('close');
            $(this).removeClass('close');
            // $(this).html('Звернути список');
        } else {
            $(this).siblings('.doc-list_wrap').addClass('close');
            $(this).addClass('close');
            // $(this).html('Дивитися всі документи (<span>' + count12 + '</span>)');
        }


});