if(typeof google !== 'undefined') {
    function CustomMarker(latlng, map, args, url) {
        this.latlng = latlng;
        this.args = args;
        this.setMap(map);
    }

    CustomMarker.prototype = new google.maps.OverlayView();

    CustomMarker.prototype.draw = function () {

        var self = this;

        var div = this.div;

        if (!div) {

            div = this.div = document.createElement('div');

            div.className = 'marker';


            if (typeof(self.args.marker_id) !== 'undefined') {
                div.className += ' marker-id-' + self.args.marker_id;
                div.dataset.marker_id = self.args.marker_id;
                if (self.args.marker_id == 0) {
                    div.className += ' active';
                }
            }
            if (typeof(self.args.html) !== 'undefined') {
                div.innerHTML = self.args.html;
            }

            google.maps.event.addDomListener(div, "click", function (event) {
                google.maps.event.trigger(self, "click");
            });

            var panes = this.getPanes();
            panes.overlayImage.appendChild(div);
        }

        var point = this.getProjection().fromLatLngToDivPixel(this.latlng);

        if (point) {
            div.style.left = (point.x) + 'px';
            div.style.top = (point.y) + 'px';
        }
    };

    CustomMarker.prototype.remove = function () {
        if (this.div) {
            this.div.parentNode.removeChild(this.div);
            this.div = null;
        }
    };

    CustomMarker.prototype.getPosition = function () {
        return this.latlng;
    };
}